import React from 'react';

const Info = () => (

    <div className="white-font info-format">
        <h1>Hello!  Welcome to our "BINGO" challenge!</h1>

        <h3>Purpose:</h3>
        <ul>
            <li>encourage movement</li>
            <li>try different formats and instructors</li>
            <li>HAVE FUN!</li>
        </ul>

        <p>This challenge is open to all levels, from beginners to extreme enthusiasts.</p>
        <p>For the beginner, it will be a challenge to complete all the squares.  It's possible! After doing a ride or run,
            add in a stretch -- that's just good practice.  You'll see that board start to fill up with checks.</p>
        <p>Our extreme enthusiast, on the other hand, will likely add in additional classes, maybe participant in other challenges,
            and will likely extend the suggested class times - exchanging 20 minute rides or runs with longer options.</p>

        {/* <h3>May Update:</h3>
        For May, we've added a mileage tracker.  Pick a goal that will challenge you.  If you plan to ride and run,
        please combine your goals for both into one for our tracker. At the bottom of your challenge board, you'll find
        a place to enter the date, number of miles completed, and any notes you want to make for yourself.  
        The miles field is the only one required to update your progress.  (Eventually, there will be an option to 
        see prior entries.) */}
        
        <h3>Board Breakout:</h3>
        <p>Each board contains the following breakout of classes with a randomly selected instructor:</p>
        <ul>
            <li>12 bike or tread squares,</li>
            <li>8 strength squares,</li>
            <li>5 yoga squares,</li>
            <li>3 to 5 special event squares,</li>
            <li>5 meditation squares,</li>
            <li>8 stretch squares, and</li>
            <li>2 wild card squares.</li>
        </ul>
        <p>Special event classes may include "theme" classes planned for the month, like top 50 count-downs, holiday classes, etc.
            Normally, these will not list an instructor.  That choice is yours!
        </p>
        <p>Wild card squares! These might be classes, or they may be challenges.  This is where that dance cardio option that you
            forgot about might pop up.  Or, it may be completing certain levels of different challenges - like gold on the activity challenge.
            And for those of us in colder climates, it's always great "fun" to see an outdoor class on your card in winter.</p>
        <p>Target durations are listed at the bottom of the card.  You are always welcome to extend those times.</p>

        <h3>Game Instructions:</h3>
        <p>The main page lists all those who have signed up in leaderboard name order.  If you're not listed, you'll need to select
            the 'Join' button near the top of the screen.</p>

        <h4>'Joining' the Game:</h4>
        <p>Your leaderboard name, or some other identifier that you can remember, is required. Please do not include "#" in your leaderboard name.
            First and Last name are not required but do make it easier to associate leaderboard names with people. </p>
        <p>The next thing you need to do is determine your primary focus. Those that select the 'zone' option will be limited to power
            zone instructors for the bike section of the card.  You will see duplicated instructors in this case.</p> 
        <p>Hit the "Let's Go" button and your card will be generated and waiting for you back on the main page.</p>

        <h4>Completing the Card:</h4>
        <p>Back on the main page, click on your square. This will bring up all the "classes" selected for you in this challenge.
            As you complete activities, click the little box in the activity square, and then press 'Save' near the bottom of the page.  
            A saved confirmation message will appear under your leaderboard name.  Using the "Home" link in the upper right corner will take you 
            back to the main page where you can verify your progress percent has been updated. </p>
        <p>The date field is optional.  The activity squares are listed in date order. If all the dates are blank, the order doesn't change, even
            as you complete classes.  If you add a date when you complete squares, those will drop down in the list.</p>
        <p>If you're a planner, you can add the dates for when you plan to complete each square and then save.  The squares should then
            appear in date order. Squares with a blank date will still be listed first.</p>

        <h3>Miscellaneous Information:</h3>  
        <p>This app was created, in part, to help me, #RedSpinShoes, learn how to do stuff.  As such, it resides on the free tier of it's hosting
            platform. This, at times, may effect it's performance.  It also subjects the app to limitations which, if exceeded, may cause 
            the app to stop functioning.  I may also need to shut down or limit participation should the app start accumulating 
            unplanned expenses. I appreciate everyone's participation!  I do learn a lot and find things I want to change or enhance all the time thanks
            to you. Most of all, I hope you enjoy playing! </p>        
    </div>
)

export default (Info);