import thunkMiddleware from 'redux-thunk'
import { createStore, applyMiddleware, combineReducers } from 'redux'
import logger from 'redux-logger'
import challengeReducer from './reducers/challengeReducers'
import participantReducer from './reducers/participantReducers'
import commentsReducer from './reducers/commentsReducers';

const rootReducer = combineReducers({
  challenge: challengeReducer,
  participant : participantReducer,
  comments : commentsReducer

})

export default () => createStore(rootReducer, applyMiddleware(logger, thunkMiddleware))