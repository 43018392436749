import React from 'react';
import './TeamLovewell.css';

export function OrnaTest(props) {

    return (
        <div >
            {props.children}
        </div>
    )
}