import { classList } from "./classList"

class BingoCard {
    constructor(cardType) {
        this.cardType = cardType;
        this.bingoCard = [];
        this.classList = classList;
    }

    buildBingoCard() {

        // this.classList.forEach(item => { item.timesUsed = 0 });
        // let classUseCap = 1;
        let classType = [
            "groove",
            "edm",
            "decades",
            "r&b",
            "holiday",
            "i&a",
            "low impact",
            "rock",
            "move as one",
            "pop",
            "climb",
            "hiit",
        ];

        for (let square = 0; square < 12; square++) {

            this.bingoCard.push({
                squareID: square,
                instructor: classType[square],
                classType: "",
                planDate: null,
                complete: false
            });
        }



        return this.bingoCard;
    }

    findClass(classUseCap, cardType) {

        let classFound = false;
        let randomNum = 0;

        while (!classFound) {
            randomNum = Math.floor(Math.random() * this.classList.length);

            if (this.classList[randomNum].classType === cardType &&
                this.classList[randomNum].timesUsed < classUseCap) {
                this.classList[randomNum].timesUsed++;
                classFound = true;
            }
        }

        return this.classList[randomNum];
    }

}

export default BingoCard
