import {
    GET_PARTICIPANTS,
    FETCH_PARTICIPANTS_FAILED,
    NEW_PARTICIPANT,
    ADD_FAILED,
    SELECTED_PARTICIPANT,
    UPDATE_PARTICIPANT_SQUARES,
    CLEAR_PARTICIPANT,
    UPDATE_FAILED
} from '../actions/participantActions'

let initialState = {
    participants: [],
    selectedParticipant: ""
}

const participantReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_PARTICIPANTS:
            return { ...state, participants: action.payload }
        case SELECTED_PARTICIPANT:
        case CLEAR_PARTICIPANT:
            return { ...state, selectedParticipant: action.payload }
        case NEW_PARTICIPANT:
            return { participants: [...state.participants, { leaderboardName: action.payload.leaderboardName, id: action.payload.id }], selectedParticipant: action.payload.leaderboardName }
        case UPDATE_PARTICIPANT_SQUARES:
            let position = -1;
            state.participants.forEach((participant, index) => {
               if (participant.leaderboardName === action.payload.leaderboardName)  position = index
            });
            let copyState = JSON.parse(JSON.stringify(state.participants))
            if (position > -1) {
                copyState[position] = action.payload;
            } else {
                copyState = [...copyState, action.payload];
            }
            return {participants : copyState, selectedParticipant : state.selectedParticipant}
        case FETCH_PARTICIPANTS_FAILED:
        case ADD_FAILED:
        case UPDATE_FAILED:
        default:
            return state
    }
}

export default participantReducer;