import {
    GET_COMMENTS, 
    ADD_COMMENT,
    ADD_FAILED
  } from '../actions/commentsActions'
  
  
  
  let initialState = [];
  
  const commentReducer = (state = initialState, action) => {
    switch (action.type) {
      case GET_COMMENTS:
        // console.log("comments:", Array.isArray(action.payload));
        return action.payload;
      case ADD_COMMENT:
          return [action.payload, ...state]
      case ADD_FAILED:
          return action.payload;
      default:
        return state
    }
  
  }
  
  export default commentReducer;