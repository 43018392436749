export const GET_COMMENTS = "GET_COMMENTS"
export const ADD_COMMENT = "ADD_COMMENT"
export const ADD_FAILED = "ADD_FAILED"

// let targetURL = 'http://localhost:8080/api/comments'

// let targetURL = 'https://taco-track.herokuapp.com/api/comments'
let targetURL = 'https://wubcit4utg.execute-api.us-east-2.amazonaws.com/dev/comments'

export const getComments = () => {
    return dispatch => {
        fetch(`${targetURL}`,
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                }
            })
            .then(response => response.json())
            .then(response =>
                dispatch({
                    type: GET_COMMENTS,
                    payload: response.Items
                })
            )
    };
}

export const addComment = (comment) => {
    return dispatch => {
        fetch(`${targetURL}/` + comment.id,
            {
                method: 'PUT',
                body: JSON.stringify(comment),
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                }
            }
        )
            .then(response => response.json())
            .then(response =>
                dispatch({
                    type: ADD_COMMENT,
                    payload: comment
                    // {
                    //     ID: Id,
                    //     message: comment
                    // }
                }))
            .catch(err => dispatch({
                type: ADD_FAILED,
                payload: err
            }))


    };
}