import React, { Component } from "react";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { clearCard } from '../actions/challengeActions.js'
import { clearParticipant } from '../actions/participantActions.js'
import { NavLink } from 'react-router-dom';

class TopNav extends Component {

    clearParticipantInfo = () => {
        // this.props.clearCard();
        this.props.clearParticipant();
    }


    render() {
        return (
            <div className="right-align" id="navlinks">
                <ul>
                    {/* <li><NavLink to="info" className="white-font">Info</NavLink></li> */}
                    <li><NavLink className="nav-link-spacing" to="/" onClick={this.clearParticipantInfo} className="white-font">Home</NavLink></li>
                </ul>

            </div>
        )
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({
    clearCard,
    clearParticipant
}, dispatch)

export default connect(null, mapDispatchToProps)(TopNav)