import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Component } from 'react';
import { getParticipantCard } from '../actions/challengeActions'
import { selectedParticipant } from '../actions/participantActions'
import { Redirect } from 'react-router';

class ParticipantList extends Component {

    handleParticipantSelected = (e) => {
        e.preventDefault();
        this.props.selectedParticipant(e.target.elements.participant.value)
        this.props.getParticipantCard(e.target.elements.participant.value)
    }

    render() {
        if (this.props.card.length > 0 ){
            return (<Redirect to="/challenge"/>)
        }
        return (
            <div>
                <form onSubmit={this.handleParticipantSelected}>
                    <select className="form-fields" id="participant">
                        {this.props.participants.map(participant =>
                            <option key={participant.id} value={participant.leaderboardName}>{participant.leaderboardName}</option>)}

                    </select>
                    <button className="form-fields">That's Me!</button>
                </form>

                {/* onChange={(event) => this.handleUserInput(event)} > */}

            </div>
        );
    }
}

const mapStateToProps = (state) => (
    { 
        participants: state.participant.participants,
        card : state.challenge
     }
)

const mapDispatchToProps = dispatch => bindActionCreators({
      getParticipantCard,
      selectedParticipant
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(ParticipantList)