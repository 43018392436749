import { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { addComment } from '../actions/commentsActions';
import CommentList from './CommentList';

class BingoComments extends Component {

    handleCommentSubmitted = (e) => {
        e.preventDefault();
        this.props.addComment({
            id: this.props.comments.length,
            from: e.target.elements.participant.value,
            comment: e.target.elements.comment.value
        });
        e.target.reset()
    }
    render() {
        return (
            <div className="comments">
                <h2>Comments:</h2>
                <form onSubmit={this.handleCommentSubmitted}>

                    <label htmlFor="participant">Name:</label>
                    <input type="text" id="participant" name="participant" required />
                    <br></br>
                    <label htmlFor="comment">Comment:</label>
                    <input className='extend-field' type="text" id="comment" name="comment" required />
                    <br></br>
                    <button className="form-fields save_updates">Submit</button>
                </form>

                <div>
                    {/* {this.props.comments.map((comment) => <CommentList key={comment.id} comment={comment} />)} */}
                    {this.props.comments.sort((val1, val2) => (val1.id < val2.id) ? 1 : -1)
                        .slice(0, 15)
                        .map((comment) => <CommentList key={comment.id} comment={comment} />)}


                    {/* {this.props.participants.sort((val1, val2) => (val1.leaderboardName.toLowerCase() < val2.leaderboardName.toLowerCase()) ? -1 : 1)
                        .map((participant, i) => <ParticipantProgress key={i} participant={participant} participantIndex={i} />)} */}
                </div>

            </div>
        )

    }
}

const mapStateToProps = (state) => {
    return {
        comments: state.comments
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({
    addComment
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(BingoComments)