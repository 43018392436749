import React, { Component } from "react";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';
import { getParticipantCard } from '../actions/challengeActions'

class ReadyToPlay extends Component {
    render() {
        if (this.props.card.length > 0 ){
            return (<Redirect to="/challenge"/>)
        }
        return (
            <div>
            <h1>Congrats!</h1>
            <h2>You're all set.</h2>
            <button onClick={() => this.props.getParticipantCard(this.props.selectedParticipant)}>Continue to card?</button>
        </div>
        )
    }
}

const mapStateToProps = (state) => (
    { 
        selectedParticipant : state.participant.selectedParticipant,
        card : state.challenge
     }
)

const mapDispatchToProps = dispatch => bindActionCreators({
      getParticipantCard
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(ReadyToPlay)