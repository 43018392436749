export const classList = [
    { instructor: "Alex Toussaint", classType: "bike", timesUsed: 0 },
    { instructor: "Ally Love", classType: "bike", timesUsed: 0 },
    { instructor: "Ben Alldis", classType: "bike", timesUsed: 0 },
    { instructor: "Bradley Rose", classType: "bike", timesUsed: 0 },
    { instructor: "Camila Ramon", classType: "bike", timesUsed: 0 },
    { instructor: "German Instructor", classType: "bike", timesUsed: 0 },
    { instructor: "Christine D'Ercole", classType: "bike", timesUsed: 0 },
    { instructor: "Cody Rigsby", classType: "bike", timesUsed: 0 },
    { instructor: "Denis Morton", classType: "bike", timesUsed: 0 },
    { instructor: "Emma Lovewell", classType: "bike", timesUsed: 0 },
    { instructor: "Hannah Corbin", classType: "bike", timesUsed: 0 },
    { instructor: "Hannah Frankson", classType: "bike", timesUsed: 0 },
    { instructor: "Jenn Sherman", classType: "bike", timesUsed: 0 },
    { instructor: "Jess King", classType: "bike", timesUsed: 0 },
    { instructor: "Kendall Toole", classType: "bike", timesUsed: 0 },
    { instructor: "Leanne Hainsby", classType: "bike", timesUsed: 0 },
    { instructor: "Matt Wilpers", classType: "bike", timesUsed: 0 },
    { instructor: "Olivia Amato", classType: "bike", timesUsed: 0 },
    { instructor: "Robin Arzon", classType: "bike", timesUsed: 0 },
    { instructor: "Sam Yo", classType: "bike", timesUsed: 0 },
    { instructor: "Tunde Oyeneyin", classType: "bike", timesUsed: 0 },
    { instructor: "Lanebreak", classType: "bike", timesUsed: 0 },
    { instructor: "Ben Alldis", classType: "power zone", timesUsed: 0 },
    { instructor: "Christine D'Ercole", classType: "power zone", timesUsed: 0 },
    { instructor: "Denis Morton", classType: "power zone", timesUsed: 0 },
    { instructor: "Matt Wilpers", classType: "power zone", timesUsed: 0 },
    { instructor: "Olivia Amato", classType: "power zone", timesUsed: 0 },
    { instructor: "Sam Yo", classType: "power zone", timesUsed: 0 },
    { instructor: "Adrian Williams", classType: "tread", timesUsed: 0 },
    { instructor: "Alex Toussaint", classType: "tread", timesUsed: 0 },
    { instructor: "Andy Speer", classType: "tread", timesUsed: 0 },
    { instructor: "Becs Gentry", classType: "tread", timesUsed: 0 },
    { instructor: "Camila Ramon", classType: "tread", timesUsed: 0 },
    { instructor: "Jermaine Johnson", classType: "tread", timesUsed: 0 },
    { instructor: "Jess Sims", classType: "tread", timesUsed: 0 },
    { instructor: "Jess King", classType: "tread", timesUsed: 0 },
    { instructor: "Jon Hosking", classType: "tread", timesUsed: 0 },
    { instructor: "Joslyn Thompson Rule", classType: "tread", timesUsed: 0 },
    { instructor: "Kirsten Ferguson", classType: "tread", timesUsed: 0 },
    { instructor: "Marcel Dinkins", classType: "tread", timesUsed: 0 },
    { instructor: "German Instructor", classType: "tread", timesUsed: 0 },
    { instructor: "Matt Wilpers", classType: "tread", timesUsed: 0 },
    { instructor: "Matty Maggiacomo", classType: "tread", timesUsed: 0 },
    { instructor: "Olivia Amato", classType: "tread", timesUsed: 0 },
    { instructor: "Rebecca Kennedy", classType: "tread", timesUsed: 0 },
    { instructor: "Robin Arzon", classType: "tread", timesUsed: 0 },
    { instructor: "Selena Samuela", classType: "tread", timesUsed: 0 },
    { instructor: "Susie Chan", classType: "tread", timesUsed: 0 },
    { instructor: "Aditi Shah", classType: "yoga", timesUsed: 0 },
    { instructor: "Anna Greenberg", classType: "yoga", timesUsed: 0 },
    { instructor: "Chelsea Jackson Roberts", classType: "yoga", timesUsed: 0 },
    { instructor: "Denis Morton", classType: "yoga", timesUsed: 0 },
    { instructor: "Kirra Michel", classType: "yoga", timesUsed: 0 },
    { instructor: "Kristin McGee", classType: "yoga", timesUsed: 0 },
    { instructor: "Mariana Fernandez", classType: "yoga", timesUsed: 0 },
    { instructor: "Ross Rayburn", classType: "yoga", timesUsed: 0 },
    { instructor: "Aditi Shah", classType: "meditation", timesUsed: 0 },
    { instructor: "Anna Greenberg", classType: "meditation", timesUsed: 0 },
    { instructor: "Chelsea Jackson Roberts", classType: "meditation", timesUsed: 0 },
    { instructor: "Cody Rigsby", classType: "meditation", timesUsed: 0 },
    { instructor: "Denis Morton", classType: "meditation", timesUsed: 0 },
    { instructor: "Kirra Michel", classType: "meditation", timesUsed: 0 },
    { instructor: "Kristin McGee", classType: "meditation", timesUsed: 0 },
    { instructor: "Ross Rayburn", classType: "meditation", timesUsed: 0 },
    { instructor: "Callie Gullickson", classType: "strength", timesUsed: 0 },
    { instructor: "Rad Lopez", classType: "strength", timesUsed: 0 },
    { instructor: "Aditi Shah", classType: "strength", timesUsed: 0 },
    { instructor: "Adrian Williams", classType: "strength", timesUsed: 0 },
    { instructor: "Alex Toussaint", classType: "strength", timesUsed: 0 },
    { instructor: "Alley Love", classType: "strength", timesUsed: 0 },
    { instructor: "Andy Speer", classType: "strength", timesUsed: 0 },
    { instructor: "Anna Greenberg", classType: "strength", timesUsed: 0 },
    { instructor: "Becs Gentry", classType: "strength", timesUsed: 0 },
    { instructor: "Ben Alldis", classType: "strength", timesUsed: 0 },
    { instructor: "Bradley Rose", classType: "strength", timesUsed: 0 },
    { instructor: "Christine D'Ercole", classType: "strength", timesUsed: 0 },
    { instructor: "Cody Rigsby", classType: "strength", timesUsed: 0 },
    { instructor: "Denis Morton", classType: "strength", timesUsed: 0 },
    { instructor: "Emma Lovewell", classType: "strength", timesUsed: 0 },
    { instructor: "Hannah Corbin", classType: "strength", timesUsed: 0 },
    { instructor: "Hannah Frankson", classType: "strength", timesUsed: 0 },
    { instructor: "Jenn Sherman", classType: "strength", timesUsed: 0 },
    { instructor: "Jess Sims", classType: "strength", timesUsed: 0 },
    { instructor: "Jess King", classType: "strength", timesUsed: 0 },
    { instructor: "Kendall Toole", classType: "strength", timesUsed: 0 },
    { instructor: "Kristen McGee", classType: "strength", timesUsed: 0 },
    { instructor: "Leanne Hainsby", classType: "strength", timesUsed: 0 },
    { instructor: "Matt Wilpers", classType: "strength", timesUsed: 0 },
    { instructor: "Matty Maggiacomo", classType: "strength", timesUsed: 0 },
    { instructor: "Olivia Amato", classType: "strength", timesUsed: 0 },
    { instructor: "Rebecca Kennedy", classType: "strength", timesUsed: 0 },
    { instructor: "Robin Arzon", classType: "strength", timesUsed: 0 },
    { instructor: "Sam Yo", classType: "strength", timesUsed: 0 },
    { instructor: "Selena Samuela", classType: "strength", timesUsed: 0 },
    { instructor: "Tunde Oyeneyin", classType: "strength", timesUsed: 0 },
    { instructor: "Logan Aldridge", classType: "strength", timesUsed: 0 },
    { instructor: "Adrian Williams", classType: "stretch", timesUsed: 0 },
    { instructor: "Alex Toussaint", classType: "stretch", timesUsed: 0 },
    { instructor: "Ally Love", classType: "stretch", timesUsed: 0 },
    { instructor: "Andy Speer", classType: "stretch", timesUsed: 0 },
    { instructor: "Becs Gentry", classType: "stretch", timesUsed: 0 },
    { instructor: "Ben Alldis", classType: "stretch", timesUsed: 0 },
    { instructor: "Christine D'Ercole", classType: "stretch", timesUsed: 0 },
    { instructor: "Cody Rigsby", classType: "stretch", timesUsed: 0 },
    { instructor: "Denis Morton", classType: "stretch", timesUsed: 0 },
    { instructor: "Emma Lovewell", classType: "stretch", timesUsed: 0 },
    { instructor: "Hannah Corbin", classType: "stretch", timesUsed: 0 },
    { instructor: "Hannah Frankson", classType: "stretch", timesUsed: 0 },
    { instructor: "Jenn Sherman", classType: "stretch", timesUsed: 0 },
    { instructor: "Jess Sims", classType: "stretch", timesUsed: 0 },
    { instructor: "Jess King", classType: "stretch", timesUsed: 0 },
    { instructor: "Kendall Toole", classType: "stretch", timesUsed: 0 },
    { instructor: "Leanne Hainsby", classType: "stretch", timesUsed: 0 },
    { instructor: "Matt Wilpers", classType: "stretch", timesUsed: 0 },
    { instructor: "Matty Maggiacomo", classType: "stretch", timesUsed: 0 },
    { instructor: "Olivia Amato", classType: "stretch", timesUsed: 0 },
    { instructor: "Rebecca Kennedy", classType: "stretch", timesUsed: 0 },
    { instructor: "Robin Arzon", classType: "stretch", timesUsed: 0 },
    { instructor: "Sam Yo", classType: "strength", timesUsed: 0 },
    { instructor: "Selena Samuela", classType: "stretch", timesUsed: 0 },
    { instructor: "Tunde Oyeneyin", classType: "stretch", timesUsed: 0 },
    { instructor: "Susie Chan", classType: "stretch", timesUsed: 0 },
    { instructor: "Marcel Dinkins", classType: "stretch", timesUsed: 0 },
    { instructor: "Kirsten Ferguson", classType: "stretch", timesUsed: 0 },
    { instructor: "Logan Aldridge", classType: "stretch", timesUsed: 0 },
    { instructor: "Just Ride/Run", classType: "other", timesUsed: 0 },
    { instructor: "Scenic Ride/Run", classType: "other", timesUsed: 0 },
    { instructor: "Activity Challenge-Gold", classType: "other", timesUsed: 0 },
    { instructor: "Ride/Run Challenge-Silver", classType: "other", timesUsed: 0 },
    // { instructor: "Spring Launch Challenge", classType: "other", timesUsed: 0 },
    { instructor: "Shadowboxing", classType: "other", timesUsed: 0 },
    { instructor: "HIIT Cardio", classType: "other", timesUsed: 0 },
    { instructor: "Dance Cardio", classType: "other", timesUsed: 0 },
    { instructor: "Outdoor", classType: "other", timesUsed: 0 },
    { instructor: "Our Future Selves", classType: "other", timesUsed: 0 },
    { instructor: "Any Live Class", classType: "other", timesUsed: 0 },
    { instructor: "LOL Cody", classType: "other", timesUsed: 0 },
    { instructor: "Bootcamp", classType: "other", timesUsed: 0 }
]