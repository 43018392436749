import React, { Component } from "react";
import { OrnaTest } from "./CSSOrnament/TeamLovewell";

class ChallengeClass extends Component {

    state = {
        classCompleted : this.props.square.complete
    }

    updateDate = (e) => {
        this.props.square.planDate = e.target.value;
        this.props.captureChange(this.props.square)
    }

    updateCompletion = (e) => {
        this.props.square.complete = !this.state.classCompleted;
        this.setState({ 
            classCompleted : this.props.square.complete
        })
        this.props.captureChange(this.props.square)
    }

    render() {
        return (
            <div className={(this.state.classCompleted) ? "ClassDone" : "TestIt"} onClick={() => this.updateCompletion()}>  
                <OrnaTest >
                <div className="emphasis-text">{this.props.square.instructor}</div>
                {/* <label className="emphasis-text" htmlFor="class">{this.props.square.instructorNum}<br/> */}
                arms<br/>core<br/>
                {/* <input type="date" onChange={(event) => this.updateDate(event)} id="class" name="class"
                    defaultValue={this.props.square.planDate} /> */}
                {/* <input type="checkbox"  id="completed" name="completed"
                    checked={this.state.classCompleted} onChange={() =>this.updateCompletion()}/>    */}
                </OrnaTest>
                {/* checked={(this.props.square.complete) ? true : false}  */}
                {/* // {(event) => this.updateCompletion(event) */}
            </div>
        )
    }
}

export default ChallengeClass;