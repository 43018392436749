import {
  FETCH_CARD_FAILED,
    GET_CHALLENGE_CARD, 
    UPDATE_CARD,
    UPDATE_FAILED,
    CLEAR_CARD
  } from '../actions/challengeActions'
  
  let initialState = []

  const challengeReducer = (state = initialState, action) => {
    switch (action.type) {
      case GET_CHALLENGE_CARD:
          return action.payload
      case UPDATE_CARD:
      case CLEAR_CARD:
          return action.payload
      case FETCH_CARD_FAILED:
      case UPDATE_FAILED:    
      default:
        return state
    }
  }
  
  export default challengeReducer;